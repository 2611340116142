import ChevronLeftIcon from "@heroicons/react/20/solid/ChevronLeftIcon"
import ChevronRightIcon from "@heroicons/react/20/solid/ChevronRightIcon"
import clsx from "clsx"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { useLocation } from "@reach/router"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { getBlogUrl, getPopularUrl } from "../../utils/i18nUrls"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Link } from "../Link/Link"
import { H1 } from "../Mdx/components"
import { Image } from "../Image/Image"

const Tab: React.FC<{
  active?: boolean
  children: ReactNode
  url: string
}> = props => (
  <Link
    url={props.url}
    className={clsx(
      "border-b mr-8 py-4 block whitespace-nowrap hover:text-slate-900",
      props.active && "border-slate-900 text-slate-900",
      !props.active && "border-transparent"
    )}
  >
    {props.children}
  </Link>
)

export const StaticPageHeading: React.FC<{
  categories: ArticleEntity["category"][]
  title: string | ReactNode
  lang: string
  logo?: {
    src: any
    alt: any
  }
  description: string | ReactNode
  tab?: string
}> = props => {
  const ref = useRef<HTMLDivElement>(null)
  const [isScrollableLeft, setScrollableLeft] = useState(false)
  const [isScrollableRight, setScrollableRight] = useState(false)

  const onArrowRight = () => {
    ref.current?.scroll({
      left: ref.current.scrollLeft + 500,
    })
  }

  const onArrowLeft = () => {
    ref.current?.scroll({
      left: ref.current.scrollLeft - 500,
    })
  }

  const onScroll = () => {
    const scrollLeft = ref.current?.scrollLeft || 0
    const scrollable = scrollLeft > 50

    if (isScrollableLeft !== scrollable) setScrollableLeft(scrollable)
  }

  useEffect(() => {
    const scrollWidth = ref.current?.scrollWidth || 0
    const offsetWidth = ref.current?.offsetWidth || 0

    if (scrollWidth > offsetWidth) setScrollableRight(true)
  }, [])

  return (
    <>
      <div className="flex mx-auto max-w-3xl lg:mt-6 items-center">
        {props.logo && (
          <Image
            className="h-10 w-10 lg:hidden inline-block mr-2"
            src={props.logo.src.publicURL}
            alt={props.logo.alt}
          />
        )}
        <h1 className="inline-block font-display leading-none text-4xl font-bold">
          {props.title}
        </h1>
      </div>
      {props.description && <p className="mt-4">{props.description}</p>}

      {props.tab && (
        <div className="border-b mt-6 sticky z-10 top-0 bg-white border-slate-100 text-slate-500">
          {isScrollableLeft && (
            <div
              className="absolute hidden cursor-pointer hover:text-slate-900 z-20 pr-6 top-0 bottom-0 bg-gradient-to-r via-white from-white md:flex items-center"
              onClick={onArrowLeft}
            >
              <ChevronLeftIcon className="h-6 w-6" />
            </div>
          )}

          {isScrollableRight && (
            <div
              className="absolute cursor-pointer hover:text-slate-900 right-0 hidden z-20 pl-6 top-0 bottom-0 bg-gradient-to-l via-white from-white md:flex items-center"
              onClick={onArrowRight}
            >
              <ChevronRightIcon className="h-6 w-6" />
            </div>
          )}

          <div
            ref={ref}
            onScroll={onScroll}
            className={clsx(
              "relative scrollbar-hide overflow-x-auto w-full font-display flex items-center"
            )}
          >
            {props.categories.map(({ name, url, id }) => (
              <Tab key={id} active={props.tab === id} url={url}>
                {name}
              </Tab>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
